import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_training_detail_banner = _resolveComponent("training-detail-banner")!
  const _component_training_detail_content = _resolveComponent("training-detail-content")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_training_detail_banner, { training: _ctx.training }, null, 8, ["training"]),
    _createVNode(_component_training_detail_content, { training: _ctx.training }, null, 8, ["training"])
  ]))
}