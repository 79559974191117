
import { defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from '@/store'
import TrainingDetailBanner from './cpns/training-detail-banner.vue'
import TrainingDetailContent from './cpns/training-detail-content.vue'

export default defineComponent({
  components: {
    TrainingDetailBanner,
    TrainingDetailContent
  },
  setup() {
    const route = useRoute()
    const store = useStore()

    const {
      params: { urlKey }
    } = route

    // 请求网络数据
    store.dispatch('training/getTrainingDetailDataAction', {
      url: `/training/${urlKey}`
    })

    // 从vuex中获取数据
    const training = computed(() => store.getters['training/getTrainingDetail'])

    return {
      training
    }
  }
})
