
import { defineComponent, PropType } from 'vue'
import { ITrainingItem } from '@/store/training/types'
import Breadcrumb from '@/components/breadcrumb'
import { useStore } from '@/store'

export default defineComponent({
  components: {
    Breadcrumb
  },
  props: {
    training: {
      type: Object as PropType<ITrainingItem>,
      required: true
    }
  },
  setup() {
    const {
      state: { login }
    } = useStore()
    const user = login.user
    return {
      user
    }
  }
})
