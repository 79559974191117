
import { defineComponent, PropType, ref } from 'vue'
import { ITrainingItem } from '@/store/training/types'

export default defineComponent({
  props: {
    training: {
      type: Object as PropType<ITrainingItem>,
      required: true
    }
  },
  setup() {
    const opened = ref(new Set<number>([]))
    const toggle = (index: number) => {
      if (opened.value.has(index)) {
        opened.value.delete(index)
      } else {
        opened.value.add(index)
      }
    }
    const actived = ref('detail')
    const active = (id: string) => {
      actived.value = id
    }
    return {
      opened,
      toggle,
      actived,
      active
    }
  }
})
